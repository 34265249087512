/* eslint-disable @typescript-eslint/no-shadow */
import {
  CardDragHandle,
  DropdownList,
  DropdownListItem,
  EntryCard,
} from '@contentful/forma-36-react-components';
import { Dispatch, useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import reorder from '../../lib/reorder';
import { ManualEntry, ManualEntryCard } from '../../types';
import { NumberedPositionContainer, RowTitle, StyledTextInput } from './styles';

export interface DragNDropAreaProps {
  elements: ManualEntryCard[];
  setElements: Dispatch<ManualEntryCard[]>;
  removeElement: (
    id: string,
    elements: ManualEntryCard[],
    setElements: Dispatch<ManualEntryCard[]>,
  ) => void;
  loadAutomaticEntries: boolean;
  loadingManualAssets: false | ManualEntry[];
}

const changeManualAssetsPosition = (
  newPosition: string,
  assetId: string,
  elements: ManualEntryCard[],
  setElements: Dispatch<ManualEntryCard[]>,
) => {
  // get asset of which we want to change position
  const asset = elements.find(({ id }) => id === assetId);
  // change the position
  const newAsset = { ...asset, position: (newPosition as unknown) as number };
  // get all other entries that will stay the same
  const otherEntries = elements.filter(({ id }) => id !== assetId);
  // create new elements array
  const newElements = [...otherEntries, newAsset];
  // sort elements by the objects position number
  newElements.sort((a, b) => a.position! - b.position!);

  setElements(newElements as ManualEntryCard[]);
};

const onDragEnd = (
  result: DropResult,
  manualAssets: ManualEntryCard[],
  setManualAssets: Dispatch<ManualEntryCard[]>,
) => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }

  const reorderedManualAssets = reorder(
    manualAssets,
    result.source.index,
    result.destination.index,
  );

  setManualAssets(reorderedManualAssets);
};

const DragNDropArea = ({
  elements,
  setElements,
  removeElement,
  loadAutomaticEntries,
  loadingManualAssets,
}: DragNDropAreaProps) => {
  useEffect(() => {
    if (!loadAutomaticEntries) {
      // remove position on each entry when switching to manual entries only
      const newElements = elements.map((asset) => {
        const newAsset = asset;

        if (newAsset.position) {
          delete newAsset.position;
        }

        return newAsset;
      });
      setElements(newElements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAutomaticEntries]);

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, elements, setElements)}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {elements &&
                elements.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={loadAutomaticEntries}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="f36-margin-bottom--xs"
                      >
                        <EntryCard
                          cardDragHandleComponent={
                            <>
                              {!loadAutomaticEntries && (
                                <CardDragHandle>Reorder card</CardDragHandle>
                              )}
                              {loadAutomaticEntries && (
                                <NumberedPositionContainer>
                                  <RowTitle>Pos.</RowTitle>
                                  <StyledTextInput
                                    id={`text-input-${item.id}`}
                                    name={`text-input-${item.id}`}
                                    onChange={(event) =>
                                      changeManualAssetsPosition(
                                        (event.target as HTMLInputElement)
                                          .value,
                                        item.id,
                                        elements,
                                        setElements,
                                      )
                                    }
                                    testId="cf-ui-text-input"
                                    width="small"
                                    willBlurOnEsc
                                    value={(item.position as unknown) as string}
                                  />
                                </NumberedPositionContainer>
                              )}
                            </>
                          }
                          contentType={item.typeDisplayName}
                          description={item.description}
                          size="default"
                          testId="cf-ui-entry-card"
                          title={item.title}
                          status={item.publishState}
                          thumbnailElement={
                            item.thumbnailUrl ? (
                              <img
                                width="100%"
                                height="100%"
                                alt={item.description}
                                style={{ objectFit: 'cover' }}
                                src={item.thumbnailUrl}
                              />
                            ) : null
                          }
                          dropdownListElements={
                            <>
                              <DropdownList>
                                <DropdownListItem isTitle>
                                  Actions
                                </DropdownListItem>
                                <DropdownListItem href="#">
                                  In Tagging Tool öffnen
                                </DropdownListItem>
                                <DropdownListItem
                                  onClick={() =>
                                    removeElement(
                                      item.id,
                                      elements,
                                      setElements,
                                    )
                                  }
                                >
                                  Remove
                                </DropdownListItem>
                              </DropdownList>
                            </>
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {loadingManualAssets &&
        loadingManualAssets.map((entry, index) => (
          <div
            key={`loading-asset-card-${index}`}
            className="f36-margin-bottom--xs"
          >
            <EntryCard loading={true} size="default" />
          </div>
        ))}
    </>
  );
};

export default DragNDropArea;
