import { TextInput } from '@contentful/forma-36-react-components';
import styled from 'styled-components';

export const NumberedPositionContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  align-items: center;
  background-color: #f7f9fa;
  border: 0;
  padding: 0;
  border-right: 1px solid #d3dce0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const RowTitle = styled.div`
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-top: 0.1rem;
  text-align: center;
  border-bottom: 1px solid #d3dce0;
  word-break: break-word;
`;

export const StyledTextInput = styled(TextInput)`
  width: 50px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 1rem;

  & input {
    text-align: center;
  }
`;
