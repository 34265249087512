import { Dispatch } from 'react';

import { ManualEntryCard } from '../types';

const removeAsset = (
  id: string,
  manualAssets: ManualEntryCard[],
  setManualAssets: Dispatch<ManualEntryCard[]>,
) => {
  const cleanedVideos = manualAssets.filter((asset) => asset.id !== id);
  setManualAssets(cleanedVideos);
};

export default removeAsset;
