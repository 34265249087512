import { EditorExtensionSDK } from '@contentful/app-sdk';
import {
  Autocomplete,
  CheckboxField,
  FieldGroup,
  FormLabel,
} from '@contentful/forma-36-react-components';
import { ChangeEvent, Dispatch, useEffect, useState } from 'react';

import changeContentTypes from '../../lib/change-content-type';
import { searchTagsByName, updateTagNames } from '../../lib/search-tags';
import { EntryType, EntryTypeGroup, Tag } from '../../types';
import SortableArea from '../sortable-area';

interface AutoEntriesOptionsAreaProps {
  sdk: EditorExtensionSDK;
  mediaType: EntryTypeGroup;
  entryTypes: EntryType[];
  setEntryTypes: Dispatch<EntryType[]>;
  loadAutomaticEntries: boolean;
}

const HandleTagChange = (tags: Tag[], setTags: Dispatch<Tag[]>, tag: Tag) => {
  if (!tags.includes(tag)) {
    setTags([...tags, tag]);
  }
};

const AutoEntriesOptionsArea = ({
  sdk,
  mediaType,
  entryTypes,
  setEntryTypes,
  loadAutomaticEntries,
}: AutoEntriesOptionsAreaProps) => {
  const [tagIncludeItems, setTagIncludeItems] = useState<Tag[]>([]);
  const [tagIncludeSearchResults, setTagIncludeSearchResults] = useState<Tag[]>(
    [],
  );
  const [isSearchingForTags, setIsSearchingForTags] = useState(false);
  const [tagExcludeItems, setTagExcludeItems] = useState<Tag[]>([]);
  const [tagExcludeSearchResults, setTagExcludeSearchResults] = useState<Tag[]>(
    [],
  );

  useEffect(() => {
    // set tags to include when initially loading the entry
    if (sdk.entry.fields.tagsToInclude.getValue()) {
      updateTagNames(
        sdk.entry.fields.tagsToInclude.getValue(),
        setTagIncludeItems,
        setIsSearchingForTags,
        sdk,
      );
    }

    if (sdk.entry.fields.tagsToExclude.getValue()) {
      updateTagNames(
        sdk.entry.fields.tagsToExclude.getValue(),
        setTagExcludeItems,
        setIsSearchingForTags,
        sdk,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sdk.entry.fields.tagsToInclude.setValue(tagIncludeItems);
  }, [tagIncludeItems, sdk.entry.fields.tagsToInclude]);

  useEffect(() => {
    sdk.entry.fields.tagsToExclude.setValue(tagExcludeItems);
  }, [tagExcludeItems, sdk.entry.fields.tagsToExclude]);

  useEffect(() => {
    setTagIncludeItems([]);
    setTagExcludeItems([]);
  }, [mediaType, loadAutomaticEntries]);

  const mediaTypes = {
    'Shows, Formate & Profile': ['Shows', 'Formate', 'Profile'],
    'App Types': [
      'Videos',
      'Memes',
      'Soundpieces',
      'Channels',
      'Formate',
      'Show',
      'Kategorien',
    ],
  };

  return (
    <>
      {loadAutomaticEntries && (
        <>
          {(mediaType === 'Shows, Formate & Profile' ||
            mediaType === 'App Types') && (
            <FieldGroup className="f36-margin-bottom--xl">
              <FormLabel
                htmlFor="someInput"
                requiredText="requiredText"
                testId="cf-ui-form-label"
              >
                Inhaltstypen
              </FormLabel>

              <FieldGroup>
                {(mediaTypes[mediaType] as EntryType[]).map(
                  (entryType, index) => (
                    <CheckboxField
                      key={mediaTypes[mediaType][index]}
                      id={`content-type-${entryType}`}
                      labelText={entryType}
                      name={`content-type-${entryType}`}
                      value={entryType}
                      checked={entryTypes.includes(entryType)}
                      onChange={(event: ChangeEvent) =>
                        changeContentTypes(event, entryTypes, setEntryTypes)
                      }
                    />
                  ),
                )}
              </FieldGroup>
            </FieldGroup>
          )}

          {['Videos', 'Memes', 'Soundpieces', 'App Types'].includes(
            mediaType,
          ) && (
            <>
              <FieldGroup className="f36-margin-bottom--xl">
                <FormLabel
                  htmlFor="someInput"
                  requiredText="requiredText"
                  testId="cf-ui-form-label"
                >
                  Tags to include
                </FormLabel>

                <Autocomplete
                  emptyListMessage="Keine Tags gefunden"
                  items={tagIncludeSearchResults}
                  children={(items) =>
                    items.map((item) => <span>{item.title}</span>)
                  }
                  name="Search"
                  noMatchesMessage="No matches"
                  onChange={(tag) =>
                    HandleTagChange(tagIncludeItems, setTagIncludeItems, tag)
                  }
                  onQueryChange={(query) =>
                    searchTagsByName(
                      query,
                      setIsSearchingForTags,
                      setTagIncludeSearchResults,
                    )
                  }
                  placeholder="Choose from spaces in your organization"
                  width="full"
                  isLoading={isSearchingForTags}
                  className="f36-margin-bottom--m"
                />

                {tagIncludeItems && (
                  <SortableArea
                    tagItems={tagIncludeItems}
                    setTagItems={setTagIncludeItems}
                  />
                )}
              </FieldGroup>

              <FieldGroup className="f36-margin-bottom--xl">
                <FormLabel
                  htmlFor="someInput"
                  requiredText="requiredText"
                  testId="cf-ui-form-label"
                >
                  Tags to exclude
                </FormLabel>

                <Autocomplete
                  emptyListMessage="Keine Tags gefunden"
                  items={tagExcludeSearchResults}
                  children={(items) =>
                    items.map((item) => <span>{item.title}</span>)
                  }
                  name="Search"
                  noMatchesMessage="No matches"
                  onChange={(tag) =>
                    HandleTagChange(tagExcludeItems, setTagExcludeItems, tag)
                  }
                  onQueryChange={(query) =>
                    searchTagsByName(
                      query,
                      setIsSearchingForTags,
                      setTagExcludeSearchResults,
                    )
                  }
                  placeholder="Choose from spaces in your organization"
                  width="full"
                  isLoading={isSearchingForTags}
                  className="f36-margin-bottom--m"
                />

                {tagExcludeItems && (
                  <SortableArea
                    tagItems={tagExcludeItems}
                    setTagItems={setTagExcludeItems}
                  />
                )}
              </FieldGroup>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AutoEntriesOptionsArea;
