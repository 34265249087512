import { Modal as ContentfulModal } from '@contentful/forma-36-react-components';
import React, { Dispatch } from 'react';

interface ModalProps {
  isShown: boolean;
  setShown: Dispatch<boolean>;
  iframeUrl: string;
  onAfterOpen?: () => void;
}

const Modal = ({ isShown, setShown, iframeUrl, onAfterOpen }: ModalProps) => (
  <ContentfulModal
    isShown={isShown}
    onAfterOpen={onAfterOpen}
    onClose={() => setShown(false)}
    size={1200}
  >
    {({ onClose }: { onClose: () => void }) => (
      <React.Fragment>
        <ContentfulModal.Header title="Energy Tagging Tool" onClose={onClose} />

        <ContentfulModal.Content>
          <iframe
            title="test"
            name="test"
            src={iframeUrl}
            width="100%"
            height="650"
            style={{ border: 'none' }}
          />
        </ContentfulModal.Content>
      </React.Fragment>
    )}
  </ContentfulModal>
);

export default Modal;
