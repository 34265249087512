import { EditorExtensionSDK } from '@contentful/app-sdk';
import cryptoRandomString from 'crypto-random-string';
import * as R from 'ramda';

import {
  ApiChannelEntry,
  ManualEntry,
  ManualEntryCard,
  PaginatedData,
  TaggingToolEntryBase,
} from '../types';
import fetchFromAppApi from './app-api/fetch-from-app-api';
import { ENTRY_TYPES_DISPLAY_NAMES } from './constants';
import fetchWithCatch from './fetch-with-catch';
import {
  getContentfulContentTypeDisplayName,
  getContentfulEntry,
  getPublishState,
} from './get-from-contentful';
import { isCmsEntryType, isTaggingToolEntryType, notNull } from './utils';

const getContentfulManualEntryCard = async (
  manualEntry: ManualEntry,
  sdk: EditorExtensionSDK,
): Promise<ManualEntryCard> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contentfulEntry: any = await getContentfulEntry(
    sdk,
    manualEntry.entryId,
  );

  return {
    id: manualEntry.id,
    entryId: manualEntry.entryId,
    type: manualEntry.type,
    typeDisplayName: await getContentfulContentTypeDisplayName(
      sdk,
      contentfulEntry.sys.contentType.sys.id,
    ),
    title: contentfulEntry.fields.title
      ? contentfulEntry.fields.title['de-CH']
      : undefined,
    publishState: getPublishState(contentfulEntry),
    position: manualEntry.position,
  } as ManualEntryCard;
};

const mapToManualEntryCard = (
  taggingToolEntry: TaggingToolEntryBase,
  manualEntries: ManualEntry[],
): ManualEntryCard | null => {
  const currentManualEntry = manualEntries.find(
    (e) => e.entryId === taggingToolEntry.id,
  );

  const {
    id: currentManualEntryId,
    type: currentManualEntryType,
    position: currentManualPosition,
  } = currentManualEntry ?? {};

  if (!currentManualEntryId || !currentManualEntryType) {
    console.warn('Could not resolve manual entry type.');
    return null;
  }

  return {
    id: currentManualEntryId,
    entryId: taggingToolEntry.id,
    type: currentManualEntryType,
    typeDisplayName: ENTRY_TYPES_DISPLAY_NAMES[currentManualEntryType],
    title: taggingToolEntry.title || currentManualEntryType,
    description: taggingToolEntry.description,
    thumbnailUrl: taggingToolEntry.imageUrl,
    position: currentManualPosition,
  };
};

const getTaggingToolManualEntryCards = async (
  manualEntries: ManualEntry[],
): Promise<ManualEntryCard[]> => {
  const entryIds = manualEntries.map((entry) => entry.entryId);

  if (entryIds.length === 0) {
    return [];
  }

  const { data, error } = await fetchWithCatch(() =>
    fetchFromAppApi(`/flexibleentities?id=${entryIds.join(',')}`),
  );

  if (error) {
    console.error(error);
    return [];
  }

  const manualTaggingToolEntries = data as PaginatedData<
    TaggingToolEntryBase[]
  >;

  if (manualTaggingToolEntries) {
    return manualTaggingToolEntries.data
      .map((taggingToolEntry) =>
        mapToManualEntryCard(taggingToolEntry, manualEntries),
      )
      .filter(notNull);
  }

  return [];
};

const getChannelManualEntryCards = async (
  manualEntries: ManualEntry[],
): Promise<ManualEntryCard[]> => {
  const entryIds = manualEntries.map((entry) => entry.entryId);

  if (entryIds.length === 0) {
    return [];
  }

  const { data, error } = await fetchWithCatch<
    PaginatedData<ApiChannelEntry[]>
  >(() => fetchFromAppApi(`/channels`));

  if (error) {
    console.error(error);
    return [];
  }

  const manualChannelEntries = (data?.data.map((channel) => ({
    id: channel.id,
    title: channel.name,
    imageUrl: channel.logo_url,
    description: channel.description_short,
  })) ?? []) as TaggingToolEntryBase[];

  if (manualChannelEntries) {
    return manualChannelEntries
      .map((taggingToolEntry) =>
        mapToManualEntryCard(taggingToolEntry, manualEntries),
      )
      .filter(notNull);
  }

  return [];
};

const getContentfulManualEntryCards = async (
  manualEntries: ManualEntry[],
  sdk: EditorExtensionSDK,
): Promise<ManualEntryCard[]> =>
  manualEntries.length === 0
    ? []
    : Promise.all(
        manualEntries.map((entry) => getContentfulManualEntryCard(entry, sdk)),
      );

const getRestManualEntryCards = (
  manualEntries: ManualEntry[],
): ManualEntryCard[] =>
  manualEntries.length === 0
    ? []
    : manualEntries.map((entry) => ({
        id: cryptoRandomString({ length: 10, type: 'url-safe' }),
        entryId: entry.entryId,
        type: entry.type,
        typeDisplayName: ENTRY_TYPES_DISPLAY_NAMES[entry.type],
        title: entry.type,
        description: entry.entryId,
        position: entry.position,
      }));

const TAGGING_TOOL_KEY = 'taggingTool';
const CMS_KEY = 'cms';
const CHANNEL_KEY = 'channel';
const REST_KEY = 'rest';

const getManualEntryCards = async (
  sdk: EditorExtensionSDK,
  initialManualEntries: ManualEntry[],
): Promise<ManualEntryCard[]> => {
  if (initialManualEntries.length === 0) {
    return [];
  }

  const getKey = (entry: ManualEntry) => {
    if (isTaggingToolEntryType(entry.type)) {
      return TAGGING_TOOL_KEY;
    }

    if (isCmsEntryType(entry.type)) {
      return CMS_KEY;
    }

    if (entry.type === CHANNEL_KEY) {
      return CHANNEL_KEY;
    }

    return REST_KEY;
  };

  const entriesBySource: Partial<
    Record<string, ManualEntry[]>
  > = R.groupBy<ManualEntry>(getKey)(initialManualEntries);

  const taggingToolManualEntryCards = await getTaggingToolManualEntryCards(
    entriesBySource[TAGGING_TOOL_KEY] ?? [],
  );

  const channelManualEntryCards = await getChannelManualEntryCards(
    entriesBySource[CHANNEL_KEY] ?? [],
  );

  const contentfulManualEntryCards = await getContentfulManualEntryCards(
    entriesBySource[CMS_KEY] ?? [],
    sdk,
  );

  const restManualEntryCards = getRestManualEntryCards(
    entriesBySource[REST_KEY] ?? [],
  );

  const manualEntryCardsByEntryId = [
    ...taggingToolManualEntryCards,
    ...channelManualEntryCards,
    ...contentfulManualEntryCards,
    ...restManualEntryCards,
  ].reduce((acc, item) => {
    acc[item.entryId] = item;

    return acc;
  }, {} as Record<string, ManualEntryCard>);

  return initialManualEntries
    .map((e) => manualEntryCardsByEntryId[e.entryId] ?? null)
    .filter(notNull);
};

export default getManualEntryCards;
