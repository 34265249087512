import { ChangeEvent, Dispatch } from 'react';

import { EntryType } from '../types';

const changeContentTypes = (
  event: ChangeEvent,
  entryTypes: EntryType[],
  setEntryTypes: Dispatch<EntryType[]>,
) => {
  const element = event.target as HTMLInputElement;
  if (!entryTypes.includes(element.value as EntryType)) {
    setEntryTypes([...entryTypes, element.value as EntryType]);
    return;
  }
  const filteredContentTypes = entryTypes.filter(
    (value) => value !== element.value,
  );
  setEntryTypes(filteredContentTypes);
};

export default changeContentTypes;
