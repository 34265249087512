import {
  CheckboxField,
  FieldGroup,
  FormLabel,
  RadioButtonField,
  TextLink,
  Workbench,
} from '@contentful/forma-36-react-components';
import { pick } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import {
  contentTypesByEntryTypeGroup,
  MANUAL_AND_AUTO_ASSETS_FIELDS_TO_PERSIST,
  MANUAL_ASSETS_FIELDS_TO_PERSIST,
} from '../../lib/constants';
import getAppInstanceParam from '../../lib/get-app-instance-params';
import { getTargetPageEntry } from '../../lib/get-from-contentful';
import getManualEntryCards from '../../lib/get-manual-entry-cards';
import useContentTypeFields from '../../lib/hooks/use-content-type-fields';
import usePrevious from '../../lib/hooks/use-previous';
import useSyncApi from '../../lib/hooks/use-sync-api';
import { openContentfulManualEntriesDialog } from '../../lib/open-entry-dialog';
import removeAsset from '../../lib/remove-asset';
import renderDialog from '../../lib/tagging-tool/render-tagging-tool-dialog';
import {
  DisplayStyleProps,
  EditorProps,
  EntryType,
  EntryTypeGroup,
  ManualEntry,
  ManualEntryCard,
  TargetPageCard,
} from '../../types';
import AutoEntriesOptionsArea from '../auto-entries-options-area';
import DragNDropArea from '../drag-n-drop-area';
import SelectInputField from '../editor-fields/select-input-field';
import TargetPageEntry from '../editor-fields/target-page-entry';
import TextInputField from '../editor-fields/text-input-field';
import Modal from '../modal';

const Entry = (props: EditorProps) => {
  const sdkEntryFields = props.sdk.entry.fields;

  const [titleValue, setTitleValue] = useState<string>(
    sdkEntryFields.title.getValue(),
  );

  const [hideTitle, setHideTitle] = useState(
    sdkEntryFields.hideTitle.getValue() || false,
  );

  const [linkTextValue, setLinkTextValue] = useState<string>(
    sdkEntryFields.linkText.getValue(),
  );

  const [targetUrlValue, setTargetUrlValue] = useState<string>(
    sdkEntryFields.targetURL.getValue(),
  );

  const [targetPageValue, setTargetPageValue] = useState<
    TargetPageCard | undefined
  >();

  const [loadingPageEntry, setLoadingPageEntry] = useState(false);
  const [manualAssets, setManualAssets] = useState<ManualEntryCard[] | null>(
    null,
  );

  // TODO. Change this bad mixed state after R3
  const [loadingManualAssets, setLoadingManualAssets] = useState<
    false | ManualEntry[]
  >(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [mediaType, setMediaType] = useState<EntryTypeGroup>(
    sdkEntryFields.entryTypeGroup.getValue() || 'Videos',
  );

  const [mediaAppType, setMediaAppType] = useState<EntryTypeGroup | null>(null);

  const [loadAutomaticEntries, setLoadAutomaticEntries] = useState<boolean>(
    !!sdkEntryFields.automaticEntries.getValue(),
  );

  const previousLoadAutomaticEntries = usePrevious(loadAutomaticEntries);

  const [entryTypes, setEntryTypes] = useState<EntryType[]>(
    sdkEntryFields.entryTypesToInclude.getValue() || [],
  );

  const [
    displayStyleDesktop,
    setDisplayStyleDesktop,
  ] = useState<DisplayStyleProps>(
    sdkEntryFields.displayStyleDesktop.getValue() || 'Grid',
  );

  const [
    displayStyleMobile,
    setDisplayStyleMobile,
  ] = useState<DisplayStyleProps>(
    sdkEntryFields.displayStyleMobile.getValue() || 'Grid',
  );
  const [numberOfRows, setNumberOfRows] = useState<string>(
    sdkEntryFields.numberOfRows.getValue() || '1',
  );

  useEffect(() => {
    const fetchTargetPageEntry = async () => {
      const result = await getTargetPageEntry(
        props.sdk,
        sdkEntryFields.targetPage.getValue().sys.id,
      );

      setTargetPageValue(result);
      setLoadingPageEntry(false);
    };

    if (sdkEntryFields.targetPage.getValue()) {
      setLoadingPageEntry(true);
      fetchTargetPageEntry();
    }

    const fetchManualEntries = async (initialManualEntries: ManualEntry[]) => {
      const result = await getManualEntryCards(props.sdk, initialManualEntries);

      setManualAssets(result);
      setLoadingManualAssets(false);
    };

    // set manual entries when initially loading the entry
    if (sdkEntryFields.manualEntries.getValue()) {
      const initialManualEntries = sdkEntryFields.manualEntries.getValue();
      setLoadingManualAssets(initialManualEntries);
      fetchManualEntries(initialManualEntries);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSyncApi(sdkEntryFields.title, titleValue);
  useSyncApi(sdkEntryFields.hideTitle, hideTitle);
  useSyncApi(sdkEntryFields.linkText, linkTextValue);
  useSyncApi(sdkEntryFields.targetURL, targetUrlValue);
  useSyncApi(sdkEntryFields.entryTypeGroup, mediaType);
  useSyncApi(sdkEntryFields.entryTypesToInclude, entryTypes);
  useSyncApi(sdkEntryFields.displayStyleDesktop, displayStyleDesktop);
  useSyncApi(sdkEntryFields.displayStyleMobile, displayStyleMobile);
  useSyncApi(sdkEntryFields.numberOfRows, numberOfRows);

  useEffect(() => {
    if (targetPageValue) {
      const cleanedTargetPage = {
        sys: { id: targetPageValue?.id, linkType: 'Entry', type: 'Link' },
      };

      sdkEntryFields.targetPage.setValue(cleanedTargetPage);
    } else {
      sdkEntryFields.targetPage.setValue(undefined);
    }
  }, [sdkEntryFields.targetPage, targetPageValue]);

  useEffect(() => {
    sdkEntryFields.automaticEntries.setValue(loadAutomaticEntries);

    if (
      loadAutomaticEntries &&
      previousLoadAutomaticEntries !== null &&
      previousLoadAutomaticEntries !== loadAutomaticEntries
    ) {
      setEntryTypes(contentTypesByEntryTypeGroup[mediaType]);
    }
  }, [
    sdkEntryFields.automaticEntries,
    loadAutomaticEntries,
    mediaType,
    previousLoadAutomaticEntries,
  ]);

  useEffect(() => {
    if (manualAssets !== null) {
      const cleanedManualAssets = manualAssets?.map((entry) =>
        loadAutomaticEntries
          ? pick(entry, MANUAL_AND_AUTO_ASSETS_FIELDS_TO_PERSIST)
          : pick(entry, MANUAL_ASSETS_FIELDS_TO_PERSIST),
      );

      sdkEntryFields.manualEntries.setValue(cleanedManualAssets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualAssets, sdkEntryFields.manualEntries]);

  const isMediaAppType = mediaType === 'App Types';

  const handlePickAssets = useCallback(
    (e) =>
      renderDialog(
        manualAssets ?? [],
        setManualAssets,
        setModalIsOpen,
        isMediaAppType,
      )(e),
    [manualAssets, setManualAssets, setModalIsOpen, isMediaAppType],
  );

  useEffect(() => {
    window.addEventListener('message', handlePickAssets);

    return () => {
      window.removeEventListener('message', handlePickAssets);
    };
  }, [handlePickAssets]);

  const {
    titleFieldName,
    targetPageAvailableContentTypes,
    linkTextFieldName,
    targetUrlFieldName,
    allowedMediaTypes,
    allowedDisplayStylesDesktop,
    allowedDisplayStylesMobile,
    allowedNumberOfRows,
  } = useContentTypeFields(props.sdk);

  const getIframeUrl = (contentMediaAppType: EntryTypeGroup | null = null) => {
    const urlString = `${getAppInstanceParam('taggingToolUrl')}/dam`;
    const result = new URL(urlString);

    if (contentMediaAppType) {
      result.searchParams.set(
        'entryType',
        contentTypesByEntryTypeGroup[contentMediaAppType][0],
      );
    } else {
      result.searchParams.set(
        'entryType',
        mediaType ? contentTypesByEntryTypeGroup[mediaType][0] : '',
      );
    }

    result.searchParams.set('contentFeedId', props.sdk.entry.getSys().id);

    return result.toString();
  };

  const renderAddEntries = (contentMediaType: EntryTypeGroup) => (
    <FieldGroup>
      <TextLink
        icon="PlusCircle"
        iconPosition="left"
        linkType="primary"
        onClick={() => {
          setMediaAppType(contentMediaType);

          openContentfulManualEntriesDialog(
            contentMediaType,
            props.sdk,
            manualAssets ?? [],
            setManualAssets,
            setModalIsOpen,
            setLoadingManualAssets,
            isMediaAppType,
          );
        }}
        testId="cf-ui-text-link"
        className="f36-margin-top--xs"
      >
        {contentMediaType} hinzufügen
      </TextLink>
    </FieldGroup>
  );

  return (
    <>
      <Workbench.Content type="text">
        <FieldGroup className="f36-margin-bottom--xl">
          <TextInputField
            title="title"
            label={titleFieldName}
            onChange={(event: ChangeEvent) =>
              setTitleValue((event.target as HTMLInputElement).value)
            }
            value={titleValue}
          />
        </FieldGroup>

        <FieldGroup className="f36-margin-bottom--xl">
          <FormLabel
            htmlFor="someInput"
            requiredText="requiredText"
            testId="cf-ui-form-label"
          >
            Titel ausblenden
          </FormLabel>
          <CheckboxField
            id="checkbox-hide-title"
            labelText="Ja, Titel ausblenden"
            name="some name"
            testId="cf-ui-checkbox-field"
            checked={hideTitle}
            onChange={() => setHideTitle(!hideTitle)}
          />
        </FieldGroup>

        <FieldGroup className="f36-margin-bottom--xl">
          <TextInputField
            title="link-text"
            label={linkTextFieldName || ''}
            onChange={(event: ChangeEvent) =>
              setLinkTextValue((event.target as HTMLInputElement).value)
            }
            value={linkTextValue}
          />
        </FieldGroup>

        <FieldGroup className="f36-margin-bottom--xl">
          <TargetPageEntry
            targetPageValue={targetPageValue}
            loadingPageEntry={loadingPageEntry}
            setTargetPageValue={setTargetPageValue}
            targetPageAvailableContentTypes={targetPageAvailableContentTypes}
            sdk={props.sdk}
          />
        </FieldGroup>

        <FieldGroup className="f36-margin-bottom--xl">
          <TextInputField
            title="target-url"
            label={targetUrlFieldName || ''}
            onChange={(event: ChangeEvent) =>
              setTargetUrlValue((event.target as HTMLInputElement).value)
            }
            value={targetUrlValue}
          />
        </FieldGroup>

        {allowedDisplayStylesDesktop && (
          <FieldGroup className="f36-margin-bottom--xl">
            <FormLabel
              htmlFor="someInput"
              requiredText="requiredText"
              testId="cf-ui-form-label"
            >
              Display Style Desktop
            </FormLabel>
            {allowedDisplayStylesDesktop.map((allowedDisplayStyle, index) => {
              const id = `displayStyleDesktop-${allowedDisplayStyle.replace(
                /\W+/g,
                '',
              )}`;

              return (
                <RadioButtonField
                  key={`${id}${index}`}
                  checked={displayStyleDesktop === allowedDisplayStyle}
                  id={id}
                  labelText={allowedDisplayStyle}
                  name={id}
                  onChange={() => setDisplayStyleDesktop(allowedDisplayStyle)}
                  testId="cf-ui-radio-button-field"
                  value={allowedDisplayStyle}
                />
              );
            })}
          </FieldGroup>
        )}

        {allowedDisplayStylesMobile && (
          <FieldGroup className="f36-margin-bottom--xl">
            <FormLabel
              htmlFor="someInput"
              requiredText="requiredText"
              testId="cf-ui-form-label"
            >
              Display Style Mobile
            </FormLabel>
            {allowedDisplayStylesMobile.map((allowedDisplayStyle, index) => {
              const id = `displayStyleMobile-${allowedDisplayStyle.replace(
                /\W+/g,
                '',
              )}`;

              return (
                <RadioButtonField
                  key={`${id}${index}`}
                  checked={displayStyleMobile === allowedDisplayStyle}
                  id={id}
                  labelText={allowedDisplayStyle}
                  name={id}
                  onChange={() => setDisplayStyleMobile(allowedDisplayStyle)}
                  testId="cf-ui-radio-button-field"
                  value={allowedDisplayStyle}
                />
              );
            })}
          </FieldGroup>
        )}

        {allowedNumberOfRows && (
          <FieldGroup className="f36-margin-bottom--xl">
            <SelectInputField
              title="number-of-rows"
              label="Anzahl Reihen"
              value={numberOfRows}
              options={allowedNumberOfRows}
              onChange={(event: ChangeEvent) =>
                setNumberOfRows((event.target as HTMLInputElement).value)
              }
            />
          </FieldGroup>
        )}

        {allowedMediaTypes && (
          <FieldGroup className="f36-margin-bottom--xl">
            <SelectInputField
              title="type"
              label="Typ"
              value={mediaType}
              options={allowedMediaTypes}
              onChange={(event: ChangeEvent) => {
                setMediaType(
                  (event.target as HTMLInputElement).value as EntryTypeGroup,
                );
                setManualAssets([]);
                setEntryTypes([]);
              }}
            />

            <FormLabel
              htmlFor="someInput"
              requiredText="requiredText"
              testId="cf-ui-form-label"
              className="f36-margin-top--xs"
            >
              Manuell ausgewählte {mediaType}
            </FormLabel>

            {manualAssets !== null && (
              <DragNDropArea
                elements={manualAssets}
                setElements={setManualAssets}
                removeElement={removeAsset}
                loadAutomaticEntries={loadAutomaticEntries}
                loadingManualAssets={loadingManualAssets}
              />
            )}

            <TextLink
              icon="PlusCircle"
              iconPosition="left"
              linkType="primary"
              onClick={() =>
                openContentfulManualEntriesDialog(
                  mediaType,
                  props.sdk,
                  manualAssets ?? [],
                  setManualAssets,
                  setModalIsOpen,
                  setLoadingManualAssets,
                )
              }
              testId="cf-ui-text-link"
              className="f36-margin-top--xs"
            >
              {mediaType} hinzufügen
            </TextLink>

            {isMediaAppType && (
              <>
                {renderAddEntries('Videos')}
                {renderAddEntries('Memes')}
                {renderAddEntries('Soundpieces')}
                {renderAddEntries('Channels')}
              </>
            )}
          </FieldGroup>
        )}

        <FieldGroup className="f36-margin-bottom--xl">
          <FormLabel
            htmlFor="someInput"
            requiredText="requiredText"
            testId="cf-ui-form-label"
          >
            Automatische Inhalte
          </FormLabel>
          <CheckboxField
            id="Checkbox1"
            labelText="Ja, automatische Inhalte laden"
            name="some name"
            testId="cf-ui-checkbox-field"
            value="yes"
            checked={loadAutomaticEntries}
            onChange={() => setLoadAutomaticEntries(!loadAutomaticEntries)}
          />
        </FieldGroup>

        <AutoEntriesOptionsArea
          sdk={props.sdk}
          mediaType={mediaType}
          entryTypes={entryTypes}
          setEntryTypes={setEntryTypes}
          loadAutomaticEntries={loadAutomaticEntries}
        />
      </Workbench.Content>

      <Modal
        isShown={modalIsOpen}
        setShown={setModalIsOpen}
        iframeUrl={getIframeUrl(mediaAppType)}
      />
    </>
  );
};

export default Entry;
