import { TextField } from '@contentful/forma-36-react-components';
import { ChangeEvent } from 'react';

interface TextInputFieldProps {
  title: string;
  label: string;
  value: string | undefined;
  onChange: (event: ChangeEvent) => void;
}

export const TextInputField = ({
  title,
  label,
  value,
  onChange,
}: TextInputFieldProps) => (
  <TextField
    id={`${title}-input`}
    labelText={label}
    name={`${title}-input`}
    onChange={onChange}
    testId="cf-ui-text-field"
    textInputProps={{
      disabled: false,
      maxLength: 100,
      placeholder: label,
    }}
    width="full"
    value={value}
  />
);

export default TextInputField;
