import { EditorExtensionSDK } from '@contentful/app-sdk';
import cryptoRandomString from 'crypto-random-string';
import { Dispatch } from 'react';

import {
  EntryTypeGroup,
  ManualEntry,
  ManualEntryCard,
  TargetPageCard,
} from '../types';
import { contentTypesByEntryTypeGroup } from './constants';
import {
  getContentfulContentTypeDisplayName,
  getPublishState,
} from './get-from-contentful';
import { isTaggingToolEntryType } from './utils';

const openDialog = (
  sdk: EditorExtensionSDK,
  availableContentTypes: string[],
  multipleEntries: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (entries: any) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (multipleEntries) {
    sdk.dialogs
      .selectMultipleEntries({
        locale: 'de-CH',
        contentTypes: availableContentTypes,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((selectedEntries: any) => callback(selectedEntries));
  } else {
    sdk.dialogs
      .selectSingleEntry({
        locale: 'de-CH',
        contentTypes: availableContentTypes,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((selectedEntry: any) => callback(selectedEntry));
  }
};

export const openContentfulManualEntriesDialog = (
  mediaType: EntryTypeGroup,
  sdk: EditorExtensionSDK,
  manualAssets: ManualEntryCard[],
  setManualAssets: Dispatch<ManualEntryCard[]>,
  setModalIsOpen: Dispatch<boolean>,
  setLoadingManualAssets: Dispatch<false | ManualEntry[]>,
  isAppTypes = false,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storeSelectedEntries = async (selectedEntries: any) => {
    if (selectedEntries.length > 0) {
      setLoadingManualAssets(selectedEntries);
      const entries: ManualEntryCard[] = await Promise.all(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        selectedEntries.map(async (selectedEntry: any) => ({
          id: cryptoRandomString({ length: 10, type: 'url-safe' }),
          entryId: selectedEntry.sys.id,
          entryLocation: 'contentful',
          type: selectedEntry.sys.contentType.sys.id,
          typeDisplayName: await getContentfulContentTypeDisplayName(
            sdk,
            selectedEntry.sys.contentType.sys.id,
          ),
          title: selectedEntry.fields.title
            ? selectedEntry.fields.title['de-CH']
            : undefined,
          publishState: getPublishState(selectedEntry),
        })),
      );

      setLoadingManualAssets(false);

      if (manualAssets.length > 0) {
        setManualAssets([...manualAssets, ...entries]);
        return;
      }

      setManualAssets(entries);
    }
  };

  if (
    ['Memes', 'Videos', 'Channels', 'Soundpieces'].includes(mediaType) ||
    isAppTypes
  ) {
    setModalIsOpen(true);
  } else {
    const availableContentTypes =
      mediaType === 'App Types'
        ? contentTypesByEntryTypeGroup[mediaType].filter(
            (entry) => !isTaggingToolEntryType(entry),
          )
        : contentTypesByEntryTypeGroup[mediaType];

    openDialog(sdk, availableContentTypes, true, storeSelectedEntries);
  }
};

export const openContentfulTargetPageEntriesDialog = (
  contentTypes: string[],
  sdk: EditorExtensionSDK,
  setTargetPageValue: Dispatch<TargetPageCard>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storeSelectedEntry = async (selectedEntry: any) => {
    if (selectedEntry) {
      const entry = {
        id: selectedEntry.sys.id,
        title: selectedEntry.fields.title
          ? selectedEntry.fields.title['de-CH']
          : undefined,
        type: await getContentfulContentTypeDisplayName(
          sdk,
          selectedEntry.sys.contentType.sys.id,
        ),
        publishState: getPublishState(selectedEntry),
      };

      setTargetPageValue(entry);
    }
  };

  openDialog(sdk, contentTypes, false, storeSelectedEntry);
};
