import cryptoRandomString from 'crypto-random-string';
import pick from 'lodash/pick';
import { Dispatch } from 'react';

import { ManualEntryCard } from '../../types';
import {
  ENTRY_TYPES_DISPLAY_NAMES,
  TAGGING_TOOL_ENTRY_FIELDS_TO_PERSIST,
  TaggingToolEntryField,
} from '../constants';
import getAppInstanceParam from '../get-app-instance-params';

const renderDialog = (
  manualAssets: ManualEntryCard[],
  setManualAssets: Dispatch<ManualEntryCard[]>,
  setShown: Dispatch<boolean>,
  isMediaAppType: boolean,
) => (e: MessageEvent) => {
  const { data, origin } = e;

  if (origin === getAppInstanceParam('taggingToolUrl')) {
    const { event } = data;
    const receivedAssets: ManualEntryCard[] = data.assets;

    if (event === 'pickedAssets' && Array.isArray(receivedAssets)) {
      const cleanedAssets: ManualEntryCard[] = receivedAssets.map((asset) => {
        const filteredAsset = pick<ManualEntryCard, TaggingToolEntryField>(
          asset,
          TAGGING_TOOL_ENTRY_FIELDS_TO_PERSIST,
        );

        const currentManualAsset = manualAssets.find(
          (manualAsset) => manualAsset.entryId === filteredAsset.id,
        );

        return {
          ...filteredAsset,
          position: currentManualAsset?.position,
          entryId: filteredAsset.id,
          typeDisplayName: ENTRY_TYPES_DISPLAY_NAMES[filteredAsset.type],
          thumbnailUrl: asset.imageUrl,
          id: cryptoRandomString({ length: 10, type: 'url-safe' }),
        };
      });

      const updatedAssets = isMediaAppType
        ? [...manualAssets, ...cleanedAssets]
        : cleanedAssets;

      setManualAssets(updatedAssets);
      setShown(false);
    }
  }
};

export default renderDialog;
