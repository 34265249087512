import '@contentful/forma-36-react-components/dist/styles.css';
import '@contentful/forma-36-fcss/dist/styles.css';
import '@contentful/forma-36-tokens/dist/css/index.css';
import './index.css';

import { EditorExtensionSDK, init, locations } from '@contentful/app-sdk';
import React from 'react';
import { render } from 'react-dom';

import EntryEditor from './components/entry-editor';
import { setCtfAppSdk } from './lib/get-ctf-app-sdk';

const App = () => {
  init((sdk) => {
    setCtfAppSdk(sdk as EditorExtensionSDK);
    const root = document.getElementById('root');

    const ComponentLocationSettings = [
      {
        location: locations.LOCATION_ENTRY_EDITOR,
        component: <EntryEditor sdk={sdk as EditorExtensionSDK} />,
      },
    ];

    // Select a component depending on a location in which the app is rendered.
    ComponentLocationSettings.forEach((componentLocationSetting) => {
      if (sdk.location.is(componentLocationSetting.location)) {
        render(componentLocationSetting.component, root);
      }
    });
  });
};

App();
