import { EditorExtensionSDK } from '@contentful/app-sdk';
import { Entry } from '@contentful/field-editor-reference/dist/types';
import { Dispatch } from 'react';

import { PaginatedData, Tag, TaggingToolTag } from '../types';
import fetchFromAppApi from './app-api/fetch-from-app-api';
import { notNull } from './utils';

const cleanTagResults = (tags: TaggingToolTag[]) => {
  const cleanedSearchResults: Tag[] = tags.map((searchResult) => ({
    taggingToolId: searchResult.id,
    contentfulEntityId: searchResult.contentful_entity_id,
    contentfulTypeId: searchResult.contentful_type_id,
    title: searchResult.title,
  }));

  return cleanedSearchResults;
};

const getSearchResults = async (
  searchQuery: string,
): Promise<PaginatedData<TaggingToolTag[]>> => {
  const path = searchQuery ? `/tags/search/${searchQuery}` : `/tags`;

  return (await fetchFromAppApi(path)).json();
};

export const searchTagsByName = async (
  query: string,
  setIsSearchingForTags: Dispatch<boolean>,
  setTagSearchResults: Dispatch<Tag[]>,
) => {
  setIsSearchingForTags(true);

  const searchResults = (await getSearchResults(query)).data;

  const cleanedSearchResults = cleanTagResults(searchResults);

  setTagSearchResults(cleanedSearchResults);
  setIsSearchingForTags(false);
};

export const updateTagNames = async (
  storedTags: Tag[],
  setTagSearchResults: Dispatch<Tag[]>,
  setIsSearchingForTags: Dispatch<boolean>,
  sdk: EditorExtensionSDK,
) => {
  setIsSearchingForTags(true);

  const tagsCollection = await sdk.space.getEntries<Entry>({
    'sys.id[in]': storedTags.map((t) => t.contentfulEntityId).join(','),
  });

  const updatedTags = storedTags
    .map((storedTag) => {
      const updatedTagVersion = tagsCollection.items.find(
        (tag) => tag.sys.id === storedTag.contentfulEntityId,
      );

      return updatedTagVersion
        ? {
            taggingToolId: storedTag.taggingToolId,
            contentfulEntityId: updatedTagVersion.sys.id,
            contentfulTypeId: updatedTagVersion.sys.contentType.sys.id,
            title: updatedTagVersion.fields.title[sdk.locales.default],
          }
        : null;
    })
    .filter(notNull);

  setTagSearchResults(updatedTags);
  setIsSearchingForTags(false);
};
