import { Pill } from '@contentful/forma-36-react-components';
import { Dispatch } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import reorder from '../../lib/reorder';
import { Tag } from '../../types';
import { StyledListContainer, StyledListItem } from './styles';

const onSortEnd = (
  tagItems: Tag[],
  oldIndex: number,
  newIndex: number,
  setTagItems: Dispatch<Tag[]>,
) => {
  const sortedTagList = reorder(tagItems, oldIndex, newIndex);
  setTagItems(sortedTagList);
};

const SortableItem = SortableElement(
  ({
    value,
    tagItems,
    setTagItems,
  }: {
    value: string;
    tagItems: Tag[];
    setTagItems: Dispatch<Tag[]>;
  }) => (
    <StyledListItem>
      <Pill
        label={value}
        onClose={() => {
          setTagItems(tagItems.filter((tagItem) => tagItem.title !== value));
        }}
        onDrag={() => null}
      />
    </StyledListItem>
  ),
);

const SortableList = SortableContainer(
  ({
    tagItems,
    setTagItems,
  }: {
    tagItems: Tag[];
    setTagItems: Dispatch<Tag[]>;
  }) => (
    <StyledListContainer>
      {tagItems.map((tag, index) => (
        <SortableItem
          key={`item-${tag.taggingToolId}`}
          index={index}
          value={tag.title}
          tagItems={tagItems}
          setTagItems={setTagItems}
        />
      ))}
    </StyledListContainer>
  ),
);

const SortableArea = ({
  tagItems,
  setTagItems,
}: {
  tagItems: Tag[];
  setTagItems: Dispatch<Tag[]>;
}) => (
  <SortableList
    axis="xy"
    tagItems={tagItems}
    setTagItems={setTagItems}
    onSortEnd={(sortEnd) =>
      onSortEnd(tagItems, sortEnd.oldIndex, sortEnd.newIndex, setTagItems)
    }
    distance={5}
  />
);

export default SortableArea;
