import { EditorExtensionSDK } from '@contentful/app-sdk';
import {
  Button,
  DropdownList,
  DropdownListItem,
  EntryCard,
  FieldGroup,
  FormLabel,
} from '@contentful/forma-36-react-components';
import React, { Dispatch } from 'react';

import { openContentfulTargetPageEntriesDialog } from '../../../lib/open-entry-dialog';
import { TargetPageCard } from '../../../types';

interface TargetPageEntryProps {
  targetPageValue: TargetPageCard | undefined;
  loadingPageEntry: boolean;
  setTargetPageValue: Dispatch<TargetPageCard | undefined>;
  targetPageAvailableContentTypes: string[];
  sdk: EditorExtensionSDK;
}

const TargetPageEntry = ({
  targetPageValue,
  loadingPageEntry,
  setTargetPageValue,
  targetPageAvailableContentTypes,
  sdk,
}: TargetPageEntryProps) => (
  <>
    <FormLabel
      htmlFor="someInput"
      requiredText="requiredText"
      testId="cf-ui-form-label"
    >
      Zielseite
    </FormLabel>
    {targetPageValue && (
      <>
        <EntryCard
          loading={loadingPageEntry}
          contentType={targetPageValue.type}
          size="default"
          testId="cf-ui-entry-card"
          title={targetPageValue.title}
          status={targetPageValue.publishState}
          dropdownListElements={
            <>
              <DropdownList>
                <DropdownListItem isTitle>Actions</DropdownListItem>
                <DropdownListItem href="#">
                  In Tagging Tool öffnen
                </DropdownListItem>
                <DropdownListItem onClick={() => setTargetPageValue(undefined)}>
                  Remove
                </DropdownListItem>
              </DropdownList>
            </>
          }
        />
      </>
    )}
    {!targetPageValue && !loadingPageEntry && (
      <FieldGroup>
        <Button
          buttonType="muted"
          size="small"
          icon="Link"
          onClick={() =>
            openContentfulTargetPageEntriesDialog(
              targetPageAvailableContentTypes,
              sdk,
              setTargetPageValue,
            )
          }
        >
          Zielseite wählen
        </Button>
      </FieldGroup>
    )}
    {loadingPageEntry && <EntryCard loading={true} size="default" />}
  </>
);

export default TargetPageEntry;
