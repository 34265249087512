import { ContentTypeField, EditorExtensionSDK } from '@contentful/app-sdk';
import { useMemo } from 'react';

import { ContentTypeInValidation, DisplayStyleProps } from '../../types';

const initTargetPageAvailableContentTypes = (
  contentTypeFields: ContentTypeField[],
) => {
  const targetPageDetails = contentTypeFields.find(
    ({ id }) => id === 'targetPage',
  );

  return (
    (targetPageDetails!.validations.find(
      (o) => 'linkContentType' in o,
    ) as ContentTypeInValidation).linkContentType || []
  );
};

// get allowed media types from content type mediaType
const initAllowedMediaTypes = (sdkContentTypeFields: ContentTypeField[]) => {
  const mediaTypeFieldDetails = sdkContentTypeFields.find(
    ({ id }) => id === 'entryTypeGroup',
  );

  return (mediaTypeFieldDetails!.validations.find(
    (o) => 'in' in o,
  ) as ContentTypeInValidation).in;
};

// get allowed display styles from content type displayStyleDesktop
const initDisplayStylesDesktop = (sdkContentTypeFields: ContentTypeField[]) => {
  const displayStyleDesktopFieldDetails = sdkContentTypeFields.find(
    ({ id }) => id === 'displayStyleDesktop',
  );

  return (displayStyleDesktopFieldDetails!.validations.find(
    (o) => 'in' in o,
  ) as ContentTypeInValidation).in as DisplayStyleProps[];
};

// get allowed display styles from content type displayStyleMobile
const initDisplayStylesMobile = (sdkContentTypeFields: ContentTypeField[]) => {
  const displayStyleMobileFieldDetails = sdkContentTypeFields.find(
    ({ id }) => id === 'displayStyleMobile',
  );

  return (displayStyleMobileFieldDetails!.validations.find(
    (o) => 'in' in o,
  ) as ContentTypeInValidation).in as DisplayStyleProps[];
};

const initAllowedNumberOfRows = (sdkContentTypeFields: ContentTypeField[]) => {
  const numberOfRowsFieldDetails = sdkContentTypeFields.find(
    ({ id }) => id === 'numberOfRows',
  );

  return (numberOfRowsFieldDetails!.validations.find(
    (o) => 'in' in o,
  ) as ContentTypeInValidation).in;
};

const useContentTypeFields = (sdk: EditorExtensionSDK) => {
  const sdkContentTypeFields = sdk.contentType.fields;

  const titleFieldName = useMemo(
    () =>
      sdkContentTypeFields.find(({ id }) => id === 'title')?.name || 'Titel',
    [sdkContentTypeFields],
  );

  const targetPageAvailableContentTypes = useMemo(
    () => initTargetPageAvailableContentTypes(sdkContentTypeFields),
    [sdkContentTypeFields],
  );

  const linkTextFieldName = useMemo(
    () => sdkContentTypeFields.find(({ id }) => id === 'linkText')?.name,
    [sdkContentTypeFields],
  );

  const targetUrlFieldName = useMemo(
    () => sdkContentTypeFields.find(({ id }) => id === 'targetURL')?.name,
    [sdkContentTypeFields],
  );

  const allowedMediaTypes = useMemo(
    () => initAllowedMediaTypes(sdkContentTypeFields),
    [sdkContentTypeFields],
  );

  const allowedDisplayStylesDesktop = useMemo(
    () => initDisplayStylesDesktop(sdkContentTypeFields),
    [sdkContentTypeFields],
  );

  const allowedDisplayStylesMobile = useMemo(
    () => initDisplayStylesMobile(sdkContentTypeFields),
    [sdkContentTypeFields],
  );

  const allowedNumberOfRows = useMemo(
    () => initAllowedNumberOfRows(sdkContentTypeFields),
    [sdkContentTypeFields],
  );

  return {
    titleFieldName,
    targetPageAvailableContentTypes,
    linkTextFieldName,
    targetUrlFieldName,
    allowedMediaTypes,
    allowedDisplayStylesDesktop,
    allowedDisplayStylesMobile,
    allowedNumberOfRows,
  };
};

export default useContentTypeFields;
