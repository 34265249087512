const reorder = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: Iterable<any> | ArrayLike<any>,
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default reorder;
