import { ContentType, EditorExtensionSDK } from '@contentful/app-sdk';

import { ContentfulEntryPublishState, TargetPageCard } from '../types';

export const getContentfulEntry = async (
  sdk: EditorExtensionSDK,
  entryId: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const entry: any = await sdk.space.getEntry(entryId);
  return entry;
};

export const getContentfulContentTypeDisplayName = async (
  sdk: EditorExtensionSDK,
  contentTypeId: string,
) => {
  const entryType: ContentType = await sdk.space.getContentType(contentTypeId);
  return entryType.name;
};

export const getContentfulEntryCardMetaData = async (
  sdk: EditorExtensionSDK,
  entryId: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const entry: any = await getContentfulEntry(sdk, entryId);
  const entryTypeDisplayName = await getContentfulContentTypeDisplayName(
    sdk,
    entry.sys.contentType.sys.id,
  );

  return {
    title: entry.fields.title['de-CH'],
    type: entryTypeDisplayName,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPublishState = (entry: any) => {
  let publishState: ContentfulEntryPublishState;

  if (entry.sys.publishedVersion) {
    publishState = 'published';

    if (entry.sys.publishedVersion + 1 < entry.sys.version) {
      publishState = 'changed';
    }
  } else {
    publishState = 'draft';
  }

  return publishState;
};

export const getTargetPageEntry = async (
  sdk: EditorExtensionSDK,
  entryId: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contentfulEntry: any = await getContentfulEntry(sdk, entryId);
  const entry: TargetPageCard = {
    id: contentfulEntry.sys.id,
    title: contentfulEntry.fields.title
      ? contentfulEntry.fields.title['de-CH']
      : undefined,
    type: await getContentfulContentTypeDisplayName(
      sdk,
      contentfulEntry.sys.contentType.sys.id,
    ),
    publishState: getPublishState(contentfulEntry),
  };

  return entry;
};
