import getAppInstanceParam from '../get-app-instance-params';
import { getAppApiUrl } from './url';

const fetchFromAppApi = (path: string, options: RequestInit = {}) => {
  const { headers, ...restOptions } = options;

  const url = `${getAppApiUrl()}${path}`;

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAppInstanceParam('taggingToolApiToken')}`,
      ...headers,
    },
    ...restOptions,
  });
};

export default fetchFromAppApi;
