import { Option, SelectField } from '@contentful/forma-36-react-components';
import React, { ChangeEvent } from 'react';

interface SelectInputFieldProps {
  title: string;
  label: string;
  value: string | undefined;
  options: string[];
  onChange: (event: ChangeEvent) => void;
}

const SelectInputField = ({
  title,
  label,
  value,
  options,
  onChange,
}: SelectInputFieldProps) => (
  <SelectField
    labelText={label}
    name="optionSelectType"
    onChange={onChange}
    selectProps={{
      width: 'full',
    }}
    testId="cf-ui-select-field"
    value={value}
    id={`${title}-input`}
  >
    {options.map((option) => (
      <Option key={option} value={option}>
        {option}
      </Option>
    ))}
  </SelectField>
);

export default SelectInputField;
