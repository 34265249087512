import { EntryType } from '../types';

const TAGGING_TOOL_TYPES: EntryType[] = ['video', 'meme', 'soundpiece'];

const BM_TYPES: EntryType[] = ['channel'];

const CMS_TYPES: EntryType[] = [
  'category',
  'station',
  'article',
  'podcast',
  'show',
  'format',
  'profile',
];

export const isTaggingToolEntryType = (entryType: EntryType) =>
  TAGGING_TOOL_TYPES.includes(entryType);

export const isCmsEntryType = (entryType: EntryType) =>
  CMS_TYPES.includes(entryType);

export const isBmEntryType = (entryType: EntryType) =>
  BM_TYPES.includes(entryType);

export const notNull = <TValue>(value: TValue | null): value is TValue =>
  value !== null;
