import styled from 'styled-components';

export const StyledListContainer = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
`;

export const StyledListItem = styled.li`
  list-style: none;
  margin: 0 10px 10px 0;
`;
