const fetchWithCatch = async <T>(fetchFn: () => Promise<Response>) => {
  let response = null;

  try {
    response = await fetchFn();

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const data: T = await response.json();

    return { response, data };
  } catch (error) {
    console.error(error);

    return {
      data: null,
      response,
      error,
    };
  }
};

export default fetchWithCatch;
