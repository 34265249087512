import { EntryFieldAPI } from '@contentful/app-sdk';
import { useEffect } from 'react';

const useSyncApi = (apiField: EntryFieldAPI, stateValue: unknown) => {
  useEffect(() => {
    apiField.setValue(stateValue);
  }, [apiField, stateValue]);
};

export default useSyncApi;
